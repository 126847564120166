import React from 'react'


const Navbar = () => {
    return (
        <>
            <nav className="main-nav">
             
                <div className="logo">
                    <h2>
                        <span>E</span>dvora
                        
                    </h2>
                </div>
            </nav>
                      
        </>

    )
}

export default Navbar

