//import logo from './logo.svg';
import './App.css';
import Navbar from "./components/Navbar"
import Display from "./components/Display"
import "./CSS/style.css";

function App() {
  return (
    <>
    <Navbar/>
    <Display/>
    </>
  );
}

export default App;
